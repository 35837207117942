import React, { useState } from 'react';
import SignupForm from './SignupForm';  // Import the SignupForm component
import './LoginForm.css';

function LoginForm() {
  const [formData, setFormData] = useState({ email: '', password: '' });
  const [showSignup, setShowSignup] = useState(false);  // State to manage form switching

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log('Login Data:', formData);
    // Handle login logic here
  };

  return (
    <div className='pt-5'>
    <div className="container login-bg ">
      <div className="row">
        {showSignup ? (
          <SignupForm setShowSignup={setShowSignup} />  // Pass setShowSignup as a prop to SignupForm
        ) : (
          <>
            <div className="col-md-6 d-flex align-items-center justify-content-center left-login-bg">
              <div className="welcome-message">
                <h1>Hello, Friend!</h1>
                <p>Enter your personal details and start your journey with us</p>
                <button 
                class="white-bg-btn"
                  
                  onClick={() => setShowSignup(true)}
                >
                  Sign Up
                </button>
              </div>
            </div>
            <div className="col-md-6 p-5">
              <h1>Sign in</h1>
              <form onSubmit={handleSubmit} className="login-form">
                <div className="mb-3">
                  <input 
                    type="email" 
                    name="email" 
                    className="form-control" 
                    placeholder='Email'
                    value={formData.email} 
                    onChange={handleChange} 
                  />
                </div>
                <div className="mb-3">
                  <input 
                    type="password" 
                    name="password" 
                    className="form-control"
                    placeholder='Password' 
                    value={formData.password} 
                    onChange={handleChange} 
                  />
                </div>
                <div className="mb-3">
                  <label>Forgot your password?</label>
                </div>
                <button type="submit" className="btn btn-primary org-bg-btn">Login</button>
              </form>
            </div>
          </>
        )}
      </div>
    </div>
    </div>
  );
}

export default LoginForm;
