import React, { useState } from 'react';
import './Timeline.css'; // Import CSS for styling
import HiveIcon from "@mui/icons-material/Hive";

const Timeline = () => {
  const [activeYear, setActiveYear] = useState('2023');
  const years = ['2023', '2024', '2028', '2032'];

  const handleYearClick = (year) => {
    setActiveYear(year);
  };

  return (

    <div className='maintimelinecontainer'>
      <div className='basetimeline-container'>
        <HiveIcon />
      <h3>Baseline </h3>
      </div>
    <div className="timeline-container">
      <div className="timeline-bar">
        {years.map((year, index) => (
          <React.Fragment key={year}>
            <div
              className={`timeline-point ${activeYear === year ? 'active' : ''}`}
              onClick={() => handleYearClick(year)}
              style={{ left: `${index * 33}%` }} // Position points evenly
            >
              <div className="timeline-circle"></div>
              <div className="timeline-year">{year}</div>
            </div>
            {index < years.length - 1 && (
              <div
                className={`timeline-segment ${
                  parseInt(year) < parseInt(activeYear) ? 'active' : ''
                }`}
                style={{ left: `${index * 33}%`, width: `${33}%` }} // Adjust width
              ></div>
            )}
          </React.Fragment>
        ))}
        <div className="timeline-line"></div>
      </div>

      <div className="content">
        {activeYear === '2023' && (
          <div
            className="content-item"
            style={{ backgroundImage: 'url(https://img.freepik.com/premium-photo/embracing-environmental-responsibility-sustainability-with-green-recycling-symbol-plants-concept-sustainable-practices-green-recycling-environmental-responsibility-plantbased-living_918839-351406.jpg?w=996)' }}
          >
            <div className="content-text">
              <p>1406 kg CO2</p>
            </div>
          </div>
        )}
        {activeYear === '2024' && (
          <div
            className="content-item"
            style={{ backgroundImage: 'url(https://img.freepik.com/premium-photo/globe-droplet-symbol-global-conservation-efforts-world-water-day-concept-water-conservation-world-water-day-globe-symbol-global-efforts-droplet-initiative_918839-376153.jpg?w=996)' }}
          >
            <div className="content-text">
              <h4>Target Reduction: 10%</h4>
              <p>1265 kg CO2</p>
            </div>
          </div>
        )}
        {activeYear === '2028' && (
          <div
            className="content-item"
            style={{ backgroundImage: 'url(https://img.freepik.com/premium-photo/promoting-renewable-energy-businesses-address-climate-change-global-warming-concept-renewable-energy-climate-change-global-warming-business-promotion-environmental-sustainability_918839-312317.jpg?w=996)' }}
          >
            <div className="content-text">
            <h4>Target Reduction: 40%</h4>
            <p>843 kg CO2</p>
            </div>
          </div>
        )}
        {activeYear === '2032' && (
          <div
            className="content-item"
            style={{ backgroundImage: 'url(https://img.freepik.com/premium-photo/beautiful-3d-globe-clean-wooden-surface-with-big-nature-backdrop-space-it-text-environment-friendly-product-advertisement-purpose-generative-ai_1042430-52795.jpg?w=996)' }}
          >
            <div className="content-text">
            <h4>Target Reduction: 81%</h4>
            <p>281 kg CO2</p>
            </div>
          </div>
        )}
      </div>
    </div>
    <div className='basetimeline-container'>
        <HiveIcon />
    
      </div>
    </div>
  );
};

export default Timeline;
