import * as React from 'react';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import StepContent from '@mui/material/StepContent';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';

const steps = [
  {
    label: 'Step 1: Yes/No Questions',
    fields: [
      { name: 'recyclingPolicy', label: 'Do you have a recycling policy?' },
      { name: 'trainingAdvice', label: 'Is training or advice given to employees to encourage and educate them about recycling and waste reduction?' },
    ],
  },
  {
    label: 'Step 2: Description Questions',
    fields: [
      { name: 'employeesCount', label: 'How many employees do you have?' },
      { name: 'workingDays', label: 'How many working days are in the calendar year?' },
      { name: 'primaryModeOfWorking', label: 'What is the primary mode of working?' },
      { name: 'avgPercentageHome', label: 'What is the average percentage of time spent working from home?' },
      { name: 'dieselVehicleCount', label: 'How many employees utilise company-provided diesel vehicles?' },
      { name: 'avgDailyMileageDiesel', label: 'What is the average daily mileage of a company-provided diesel vehicle (in miles)?' },
      { name: 'petrolVehicleCount', label: 'How many employees utilise company-provided petrol vehicles?' },
      { name: 'avgDailyMileagePetrol', label: 'What is the average daily mileage of a company-provided petrol vehicle (in miles)?' },
      { name: 'hybridVehicleCount', label: 'How many employees utilise company-provided hybrid vehicles?' },
      { name: 'avgDailyMileageHybrid', label: 'What is the average daily mileage of a company-provided hybrid vehicle (in miles)?' },
      { name: 'electricVehicleCount', label: 'How many employees utilise company-provided electric vehicles?' },
      { name: 'avgDailyMileageElectric', label: 'What is the average daily mileage of a company-provided electric vehicle (in miles)?' },
      { name: 'motorbikeCount', label: 'How many employees utilise company-provided motorbikes?' },
      { name: 'avgDailyMileageMotorbike', label: 'What is the average daily mileage of a company-provided motorbike (in miles)?' },
      { name: 'wasteBagsType', label: 'What type of waste bags are you using?' },
      { name: 'avgWasteBagsDisposed', label: 'How many waste bags do you dispose of each day, on average?' },
      { name: 'recycledMaterials', label: 'Which materials do you currently recycle within your workspace?' },
      { name: 'colourCodedBins', label: 'Which waste bins are colour-coded?' },
    ],
  },
];

export default function VerticalLinearStepper() {
  const [activeStep, setActiveStep] = React.useState(0);
  const [formData, setFormData] = React.useState({
    recyclingPolicy: '',
    trainingAdvice: '',
    employeesCount: '',
    workingDays: '',
    primaryModeOfWorking: '',
    avgPercentageHome: '',
    dieselVehicleCount: '',
    avgDailyMileageDiesel: '',
    petrolVehicleCount: '',
    avgDailyMileagePetrol: '',
    hybridVehicleCount: '',
    avgDailyMileageHybrid: '',
    electricVehicleCount: '',
    avgDailyMileageElectric: '',
    motorbikeCount: '',
    avgDailyMileageMotorbike: '',
    wasteBagsType: '',
    avgWasteBagsDisposed: '',
    recycledMaterials: '',
    colourCodedBins: '',
  });

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
    setFormData({
      recyclingPolicy: '',
      trainingAdvice: '',
      employeesCount: '',
      workingDays: '',
      primaryModeOfWorking: '',
      avgPercentageHome: '',
      dieselVehicleCount: '',
      avgDailyMileageDiesel: '',
      petrolVehicleCount: '',
      avgDailyMileagePetrol: '',
      hybridVehicleCount: '',
      avgDailyMileageHybrid: '',
      electricVehicleCount: '',
      avgDailyMileageElectric: '',
      motorbikeCount: '',
      avgDailyMileageMotorbike: '',
      wasteBagsType: '',
      avgWasteBagsDisposed: '',
      recycledMaterials: '',
      colourCodedBins: '',
    });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = () => {
    console.log('Form Data Submitted:', formData);
  };

  return (
    <Box sx={{ maxWidth: 800 }}>
      <Stepper activeStep={activeStep} orientation="vertical">
        {steps.map((step, index) => (
          <Step key={step.label}>
            <StepLabel>{step.label}</StepLabel>
            <StepContent>
              {step.fields.map((field) => (
                step.label === 'Step 1: Yes/No Questions' ? (
                  <FormControl fullWidth key={field.name} sx={{ mt: 2 }}>
                    <InputLabel 
                      sx={{ background: '#fff7e1', px: 1 }}
                    >
                      {field.label}
                    </InputLabel>
                    <Select
                      name={field.name}
                      value={formData[field.name]}
                      onChange={handleChange}
                      label={field.label}
                    >
                      <MenuItem value="yes">Yes</MenuItem>
                      <MenuItem value="no">No</MenuItem>
                    </Select>
                  </FormControl>
                ) : (
                  <TextField
                    key={field.name}
                    name={field.name}
                    label={field.label}
                    value={formData[field.name]}
                    onChange={handleChange}
                    fullWidth
                    sx={{ mt: 2 }}
                  />
                )
              ))}
              <Box sx={{ mb: 2 }}>
                <div>
                  <Button
                    variant="contained"
                    onClick={handleNext}
                    sx={{ mt: 1, mr: 1 }}
                  >
                    {index === steps.length - 1 ? 'Finish' : 'Continue'}
                  </Button>
                  <Button
                    disabled={index === 0}
                    onClick={handleBack}
                    sx={{ mt: 1, mr: 1 }}
                  >
                    Back
                  </Button>
                </div>
              </Box>
            </StepContent>
          </Step>
        ))}
      </Stepper>
      {activeStep === steps.length && (
        <Paper square elevation={0} sx={{ p: 3 }}>
          <Typography>All steps completed - you're finished</Typography>
          <Button
            variant="contained"
            onClick={handleSubmit}
            sx={{ mt: 1, mr: 1 }}
          >
            Submit
          </Button>
          <Button onClick={handleReset} sx={{ mt: 1, mr: 1 }}>
            Reset
          </Button>
        </Paper>
      )}
    </Box>
  );
}
