import React from "react";
import "./Beeboard.css";
import Timeline from "./Timeline";

function Beeboard() {
  return (
    <div className="beadboardmain">
      <h3 className="allh3">MY BEE BOARD</h3>
      <div className="beeboardtimeline">
      <Timeline />
      </div>
 
      <div className="bbsecondsection">
        <div className="bbsecondsectionf">
          <div className="bbsecondsectionf1">
            <h2 className="allbbh2">Your current carbon emissions</h2>
            <h2 className="allbbh2">1406 kg CO2</h2>
          </div>
          <div className="bbsecondsectionf2">
            <div className="bbsecondsectionf2inner" >
              {" "}
              <h2 className="allbbh2">2024 goal</h2>
              <h2 className="allbbh2">1265 kg CO2</h2>
            </div>
            <div className="bbsecondsectionf2innerright">
              <h2 className="allbbh2">10%</h2>
              <h6 className="allbbh6">Reduction</h6>
            </div>
          </div>
          <div className="bbsecondsectionf3">
            <div className="bbsecondsectionf3inner">
              {" "}
              <h2 className="allbbh2">2028 goal</h2>
              <h2 className="allbbh2">843 kg C02</h2>
            </div>
            <div className="bbsecondsectionf3innerright">
              {" "}
              <h2 className="allbbh2">40%</h2>
              <h6 className="allbbh6">Reduction</h6>
            </div>
          </div>
          <div className="bbsecondsectionf4">
            <div className="bbsecondsectionf4inner">
              {" "}
              <h2 className="allbbh2">2032 goal</h2>
              <h2 className="allbbh2">281 kg C02</h2>
            </div>
            <div className="bbsecondsectionf4innerright">
              {" "}
              <h2 className="allbbh2">80%</h2>
              <h6 className="allbbh6">Reduction</h6>
            </div>
          </div>
          <div className="bbsecondsectionf5">
            <h2 className="allbbh2">REWARDS</h2>
          </div>
          <div className="bbsecondsectionf6">
            <div>
              {" "}
              <h2 className="allbbh2">Potential savings</h2>
            </div>
            <div>
              {" "}
              <h3 className="allbbh2">2023</h3>
              <h6 className="allbbh6">341 £/year</h6>{" "}
            </div>
            <div>
              {" "}
              <h3 className="allbbh2">2024</h3>
              <h6 className="allbbh6">341 £/year</h6>{" "}
            </div>
            <div>
              {" "}
              <h3 className="allbbh2">2028</h3>
              <h6 className="allbbh6">341 £/year</h6>{" "}
            </div>
            <div>
              {" "}
              <h3 className="allbbh2">2032</h3>
              <h6 className="allbbh6">341 £/year</h6>{" "}
            </div>
          </div>
        </div>
        <div className="bbInnerSections">
          <h2 className="allbbh2">Progress Bar</h2>
          <div className="progress-container">
            <div className="line"></div>
            <div className="step-container">
              <div className="step">
                <div className="circle">1</div>
                <div className="text">Complete Audit</div>
              </div>
              <div className="step">
                <div className="circle">2</div>
                <div className="text">Complete actions</div>
              </div>
              <div className="step">
                <div className="circle">3</div>
                <div className="text">Funds and Certificates</div>
              </div>
              <div className="step">
                <div className="circle">4</div>
                <div className="text">Achieve net zero goals</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Beeboard;
