import * as React from 'react';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import StepContent from '@mui/material/StepContent';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';

const steps = [
  {
    label: 'Step 1: Yes/No Questions',
    fields: [
      { name: 'itEquipmentEfficient', label: 'Is your IT equipment: computers, printers, photocopiers, etc. energy-efficient?' },
      { name: 'otherElectricalItemsEfficient', label: 'Are your other electrical items energy-efficient?' },
      { name: 'equipmentMaintained', label: 'Is this equipment maintained regularly?' },
      { name: 'itemsTurnedOff', label: 'Are electrical items turned off at the mains when not in use?' },
      { name: 'energySavingMode', label: 'Are computers, photocopiers, fax machines, etc. on energy saving mode during the day?' },
      { name: 'standbyMode', label: 'Are electrical items such as projectors or TVs left on standby mode?' },
      { name: 'liftInspected', label: 'If a lift is installed, is it regularly inspected and maintained?' },
      { name: 'equipmentCleaned', label: 'Is equipment cleaned and maintained regularly?' },
      { name: 'heatGeneratingItemsVentilated', label: 'For electrical items that generate heat, are these items placed in well-ventilated areas, and away from cooling items, such as fridges?' },
      { name: 'fridgesFreezersDefrosted', label: 'Are fridges and freezers regularly defrosted and their thermostats set to the correct temperature?' },
    ],
  },
  {
    label: 'Step 2: Description Questions',
    fields: [
      { name: 'nonEfficientItems', label: 'Which items of equipment are not energy-efficient?' },
      { name: 'desktopPCs', label: 'How many desktop PCs do you have?' },
      { name: 'monitors', label: 'How many monitors do you have?' },
      { name: 'printers', label: 'How many printers do you have?' },
      { name: 'TVs', label: 'How many TVs do you have?' },
      { name: 'refrigerators', label: 'How many refrigerators do you have?' },
      { name: 'microwaves', label: 'How many microwaves do you have?' },
      { name: 'coffeeMachines', label: 'How many coffee machines do you have?' },
      { name: 'electricKettles', label: 'How many electric kettles do you have?' },
      { name: 'fireAlarms', label: 'How many fire alarms do you have?' },
      { name: 'securityCameras', label: 'How many security cameras do you have?' },
      { name: 'fireSprinklerSystems', label: 'How many fire sprinkler systems do you have?' },
    ],
  },
];

export default function VerticalLinearStepper() {
  const [activeStep, setActiveStep] = React.useState(0);
  const [formData, setFormData] = React.useState({
    itEquipmentEfficient: '',
    otherElectricalItemsEfficient: '',
    equipmentMaintained: '',
    itemsTurnedOff: '',
    energySavingMode: '',
    standbyMode: '',
    liftInspected: '',
    equipmentCleaned: '',
    heatGeneratingItemsVentilated: '',
    fridgesFreezersDefrosted: '',
    nonEfficientItems: '',
    desktopPCs: '',
    monitors: '',
    printers: '',
    TVs: '',
    refrigerators: '',
    microwaves: '',
    coffeeMachines: '',
    electricKettles: '',
    fireAlarms: '',
    securityCameras: '',
    fireSprinklerSystems: '',
  });

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
    setFormData({
      itEquipmentEfficient: '',
      otherElectricalItemsEfficient: '',
      equipmentMaintained: '',
      itemsTurnedOff: '',
      energySavingMode: '',
      standbyMode: '',
      liftInspected: '',
      equipmentCleaned: '',
      heatGeneratingItemsVentilated: '',
      fridgesFreezersDefrosted: '',
      nonEfficientItems: '',
      desktopPCs: '',
      monitors: '',
      printers: '',
      TVs: '',
      refrigerators: '',
      microwaves: '',
      coffeeMachines: '',
      electricKettles: '',
      fireAlarms: '',
      securityCameras: '',
      fireSprinklerSystems: '',
    });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = () => {
    console.log('Form Data Submitted:', formData);
  };

  return (
    <Box sx={{ maxWidth: 800 }}>
      <Stepper activeStep={activeStep} orientation="vertical">
        {steps.map((step, index) => (
          <Step key={step.label}>
            <StepLabel>{step.label}</StepLabel>
            <StepContent>
              {step.fields.map((field) => (
                step.label === 'Step 1: Yes/No Questions' ? (
                  <FormControl fullWidth key={field.name} sx={{ mt: 2 }}>
                    <InputLabel 
                      sx={{ background: '#fff7e1', px: 1 }}
                    >
                      {field.label}
                    </InputLabel>
                    <Select
                      name={field.name}
                      value={formData[field.name]}
                      onChange={handleChange}
                      label={field.label}
                    >
                      <MenuItem value="yes">Yes</MenuItem>
                      <MenuItem value="no">No</MenuItem>
                    </Select>
                  </FormControl>
                ) : (
                  <TextField
                    key={field.name}
                    name={field.name}
                    label={field.label}
                    value={formData[field.name]}
                    onChange={handleChange}
                    fullWidth
                    sx={{ mt: 2 }}
                  />
                )
              ))}
              <Box sx={{ mb: 2 }}>
                <div>
                  <Button
                    variant="contained"
                    onClick={handleNext}
                    sx={{ mt: 1, mr: 1 }}
                  >
                    {index === steps.length - 1 ? 'Finish' : 'Continue'}
                  </Button>
                  <Button
                    disabled={index === 0}
                    onClick={handleBack}
                    sx={{ mt: 1, mr: 1 }}
                  >
                    Back
                  </Button>
                </div>
              </Box>
            </StepContent>
          </Step>
        ))}
      </Stepper>
      {activeStep === steps.length && (
        <Paper square elevation={0} sx={{ p: 3 }}>
          <Typography>All steps completed - you're finished</Typography>
          <Button
            variant="contained"
            onClick={handleSubmit}
            sx={{ mt: 1, mr: 1 }}
          >
            Submit
          </Button>
          <Button onClick={handleReset} sx={{ mt: 1, mr: 1 }}>
            Reset
          </Button>
        </Paper>
      )}
    </Box>
  );
}
