import * as React from 'react';
import { styled } from '@mui/material/styles';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import './Bill.css'; // Import your CSS file
import DriveFolderUploadIcon from '@mui/icons-material/DriveFolderUpload';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { Link } from "react-router-dom";
import ForwardIcon from '@mui/icons-material/Forward';
import { red } from '@mui/material/colors';

const AntTabs = styled(Tabs)({
  borderBottom: '1px solid #e8e8e8',
  '& .MuiTabs-indicator': {
    backgroundColor: '#1890ff',
  },
});

const AntTab = styled((props) => <Tab disableRipple {...props} />)(({ theme }) => ({
  textTransform: 'none',
  minWidth: 0,
  [theme.breakpoints.up('sm')]: {
    minWidth: 0,
  },
  fontWeight: theme.typography.fontWeightRegular,
  marginRight: theme.spacing(1),
  color: 'rgba(0, 0, 0, 0.85)',
  fontFamily: [
    '-apple-system',
    'BlinkMacSystemFont',
    '"Segoe UI"',
    'Roboto',
    '"Helvetica Neue"',
    'Arial',
    'sans-serif',
    '"Apple Color Emoji"',
    '"Segoe UI Emoji"',
    '"Segoe UI Symbol"',
  ].join(','),
  '&:hover': {
    color: '#40a9ff',
    opacity: 1,
  },
  '&.Mui-selected': {
    color: '#1890ff',
    fontWeight: theme.typography.fontWeightMedium,
  },
  '&.Mui-focusVisible': {
    backgroundColor: '#d1eaff',
  },
}));

export default function CustomizedTabs() {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div className='bill-container'>


    <Box sx={{ width: '100%' }}>
      <div className="billfirstsection">
        {/* Additional Section with Title and Paragraph */}
        <h4 >
       <DriveFolderUploadIcon /> Upload energy bills <HelpOutlineIcon />
        </h4>
      
      </div>
      <Box sx={{ bgcolor: '#fff' }}>
        <AntTabs value={value} onChange={handleChange} aria-label="ant example" className='billtop'>
          <AntTab label="Electricity" className='billtopitem' />
          <AntTab label="Gas" className='billtopitem' />
          <AntTab label="Water" className='billtopitem' />
          <AntTab label="Transport" className='billtopitem' />
        </AntTabs>
        <Box sx={{ p: 3 }}>
          {/* Forms for each tab */}
          {value === 0 && (
            <form>
              <TextField
                fullWidth
                variant="outlined"
                type="file"
                label="Upload Electricity File"
                InputLabelProps={{ shrink: true }}
                sx={{ mb: 2 }}
              />
              <Button variant="contained" color="primary" type="submit">
                Submit Electricity
              </Button>
            </form>
          )}
          {value === 1 && (
            <form>
              <TextField
                fullWidth
                variant="outlined"
                type="file"
                label="Upload Gas File"
                InputLabelProps={{ shrink: true }}
                sx={{ mb: 2 }}
              />
              <Button variant="contained" color="primary" type="submit">
                Submit Gas
              </Button>
            </form>
          )}
          {value === 2 && (
            <form>
              <TextField
                fullWidth
                variant="outlined"
                type="file"
                label="Upload Water File"
                InputLabelProps={{ shrink: true }}
                sx={{ mb: 2 }}
              />
              <Button variant="contained" color="primary" type="submit">
                Submit Water
              </Button>
            </form>
          )}
          {value === 3 && (
            <form>
              <TextField
                fullWidth
                variant="outlined"
                type="file"
                label="Upload Transport File"
                InputLabelProps={{ shrink: true }}
                sx={{ mb: 2 }}
              />
              <Button variant="contained" color="primary" type="submit">
                Submit Transport
              </Button>
            </form>
          )}
        </Box>
      </Box>
     <div className='billlastsection'>
      <div className="bottom-column">
        <div className="bottom-content">       
          <p> You did it! We’re excited to see your commitment to becoming sustainable. We’ll update you as soon as we get an estimate on your carbon emissions. Our team will analyze the information you provided to get the best results.
          </p>
          <p>
          It will take at least 7-10 working days to create your action plan.
          </p>
        </div>
      </div>
      </div>
      <div className="arrow-container">
        <Link to="/CarbonEmission" className="arrow" >
        <ForwardIcon/></Link>
      </div>
    </Box>
    </div>
  );
}
