import React from 'react';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import StepContent from '@mui/material/StepContent';
import Paper from '@mui/material/Paper';
import './Stepper.css';
import { ReactComponent as LightIcon } from "../assets/light.svg"; // Adjust path if necessary
import { ReactComponent as HvacIcon } from "../assets/hvac.svg"; // Adjust path if necessary
import { ReactComponent as BuildingIcon } from "../assets/building.svg"; // Adjust path if necessary
import { ReactComponent as HotwaterIcon } from "../assets/hotwater.svg"; // Adjust path if necessary
import { ReactComponent as AwarenessIcon } from "../assets/awarnes.svg"; // Adjust path if necessary
import { ReactComponent as PropertyIcon } from "../assets/property.svg"; // Adjust path if necessary
import { ReactComponent as WorkenergyIcon } from "../assets/workenergy.svg"; // Adjust path if necessary
import { ReactComponent as EquipmentIcon } from "../assets/equipment.svg"; // Adjust path if necessary
import Lightform from './Lightform';
import Hvacform from './Hvacform';
import Buildingfabricform from './Buildingfabricform';
import Hotwaterform from './Hotwaterform';
import Awarnessform from './Awarnessform';
import Equipment from './Equipment';
import Workenvironmentform from './Workenvironmentform';
import Propertyform from './Propertyform';
import { Link } from 'react-router-dom';


const steps = [
  'Light',
  'HVAC',
  'Building Fabric',
  'Hotwater',
  'Awareness',
  'Equipment',
  'Work Environment',
  'Property'
];




function HorizontalLinearStepper() {
  const [activeStep, setActiveStep] = React.useState(0);
  const [skipped, setSkipped] = React.useState(new Set());

  const isStepSkipped = (step) => {
    return skipped.has(step);
  };

  const handleNext = () => {
    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped(newSkipped);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
    setSkipped(new Set());
  };

  const renderStepContent = (step) => {
    switch (step) {
      case 0:
        return (
          <Box component="form" noValidate autoComplete="off">
            <div className="container-inner-top">
        <div className="container-inner-box">
          <div className="">
            {" "}
            <LightIcon width="50" height="50" />{" "}
          </div>
        </div>
        <div className="container-inner">
          <h2 className="text-content">Lighting</h2>
        </div>
      </div>
      <Lightform />
          </Box>
        );
      case 1:
        return (
          <Box component="form" noValidate autoComplete="off">
             <div className="container-inner-top">
        <div className="container-inner-box">
          <div className="">
            {" "}
            <HvacIcon width="50" height="50" />{" "}
          </div>
        </div>
        <div className="container-inner">
          <h2 className="text-content">HVAC</h2>
        </div>
      </div>
      <Hvacform />
          </Box>
        );
      case 2:
        return (
          <Box component="form" noValidate autoComplete="off">
             <div className="container-inner-top">
        <div className="container-inner-box">
          <div className="">
            {" "}
            <BuildingIcon width="50" height="50" />{" "}
          </div>
        </div>
        <div className="container-inner">
          <h2 className="text-content">Building Fabric</h2>
        </div>
      </div>
      <Buildingfabricform />
          </Box>
        );
      case 3:
        return (
          <Box component="form" noValidate autoComplete="off">
             <div className="container-inner-top">
        <div className="container-inner-box">
          <div className="">
            {" "}
            <HotwaterIcon width="50" height="50" />{" "}
          </div>
        </div>
        <div className="container-inner">
          <h2 className="text-content">Hotwater
          </h2>
        </div>
      </div>
      <Hotwaterform />
          </Box>
        );
      case 4:
        return (
          <Box component="form" noValidate autoComplete="off">
           
           <div className="container-inner-top">
        <div className="container-inner-box">
          <div className="">
            {" "}
            <AwarenessIcon width="50" height="50" />{" "}
          </div>
        </div>
        <div className="container-inner">
          <h2 className="text-content">Awareness</h2>
        </div>
      </div>
      <Awarnessform />
          </Box>
        );
      case 5:
        return (
          <Box component="form" noValidate autoComplete="off">
            <div className="container-inner-top">
        <div className="container-inner-box">
          <div className="">
            {" "}
            <EquipmentIcon width="50" height="50" />{" "}
          </div>
        </div>
        <div className="container-inner">
          <h2 className="text-content">Equipment
          </h2>
        </div>
      </div>
      <Equipment />
          </Box>
        );
      case 6:
        return (
          <Box component="form" noValidate autoComplete="off">
            <div className="container-inner-top">
        <div className="container-inner-box">
          <div className="">
            {" "}
            <WorkenergyIcon width="50" height="50" />{" "}
          </div>
        </div>
        <div className="container-inner">
          <h2 className="text-content">Work Environment</h2>
        </div>
      </div>
      <Workenvironmentform />
          </Box>
        );
      case 7:
        return (
          <Box component="form" noValidate autoComplete="off">
             <div className="container-inner-top">
        <div className="container-inner-box">
          <div className="">
            {" "}
            <LightIcon width="50" height="50" />{" "}
          </div>
        </div>
        <div className="container-inner">
          <h2 className="text-content">Property</h2>
        </div>
      </div>
      <Propertyform />          
      </Box>
        );
      default:
        return 'Unknown step';
    }
  };

  return (
    <div className='steppercontainers'>
    <Box sx={{ width: '100%' }}>
      <Stepper activeStep={activeStep}>
        {steps.map((label, index) => {
          const stepProps = {};
          const labelProps = {};
          if (isStepSkipped(index)) {
            stepProps.completed = false;
          }
          return (
            <Step key={label} {...stepProps}>
              <StepLabel {...labelProps}>{label}</StepLabel>
            </Step>
          );
        })}
      </Stepper>
      {activeStep === steps.length ? (
        <React.Fragment>
          <Typography sx={{ mt: 2, mb: 1 }}>
            All steps completed - you're finished
          </Typography>
          <Link to="/CarbonEmission">
            <button className="button">Next</button>
          </Link>
          <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
            <Box sx={{ flex: '1 1 auto' }} />
            <Button onClick={handleReset}>Reset</Button>
          </Box>
        </React.Fragment>
      ) : (
        <React.Fragment>
          <Typography sx={{ mt: 2, mb: 1 }}>Step {activeStep + 1}</Typography>
          {renderStepContent(activeStep)}
          <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
            <Button
              color="inherit"
              disabled={activeStep === 0}
              onClick={handleBack}
              sx={{ mr: 1 }}
            >
              Back
            </Button>
            <Box sx={{ flex: '1 1 auto' }} />
            <Button onClick={handleNext}>
              {activeStep === steps.length - 1 ? 'Finish' : 'Next'}
            </Button>
          </Box>
        </React.Fragment>
      )}
    </Box>
    </div>
  );
}

export default HorizontalLinearStepper;
