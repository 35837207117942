import React, { useState } from 'react';
import './SignupForm.css';

function SignupForm({ setShowSignup }) {
  const [formData, setFormData] = useState({
    name: '',
    industry: '',
    numberOfEmployees: '',
    companyName: '',
    email: '',
    contactNo: '',
    profileImage: null,
  });

  const handleChange = (e) => {
    const { name, value, files } = e.target;
    if (name === 'profileImage') {
      setFormData((prevData) => ({
        ...prevData,
        [name]: files[0]
      }));
    } else {
      setFormData((prevData) => ({
        ...prevData,
        [name]: value
      }));
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log('Sign Up Data:', formData);
    // Handle sign up logic here
  };

  return (
    <div className="container ">
      <div className="row">
        <div className="col-md-6 p-5">       
          <h1>Create Account</h1>
          <form onSubmit={handleSubmit} className="signup-form">
            <div className="row">
              <div className="mb-3 col-md-6">
                <input
                  type="text"
                  name="name"
                  className="form-control"
                  placeholder='Name'
                  value={formData.name}
                  onChange={handleChange}
                />
              </div>
              <div className="mb-3 col-md-6">
                <input
                  type="text"
                  name="industry"
                  className="form-control"
                  placeholder='Industry'
                  value={formData.industry}
                  onChange={handleChange}
                />
              </div>
            </div>
            <div className="row">
              <div className="mb-3 col-md-6">
                <input
                  type="number"
                  name="numberOfEmployees"
                  className="form-control"
                  placeholder='Number of Employees'
                  value={formData.numberOfEmployees}
                  onChange={handleChange}
                />
              </div>
              <div className="mb-3 col-md-6">
                <input
                  type="text"
                  name="companyName"
                  className="form-control"
                  placeholder='Company Name'
                  value={formData.companyName}
                  onChange={handleChange}
                />
              </div>
            </div>
            <div className="mb-3">
              <input
                type="email"
                name="email"
                placeholder='Email'
                className="form-control"
                value={formData.email}
                onChange={handleChange}
              />
            </div>
            <div className="mb-3">
              <input
                type="text"
                name="contactNo"
                className="form-control"
                placeholder='Contact No'
                value={formData.contactNo}
                onChange={handleChange}
              />
            </div>
            <div className="mb-3">
              <input
                type="file"
                name="profileImage"
                className="form-control"
                placeholder='Profile Image'
                onChange={handleChange}
              />
            </div>
            <button type="submit" className="btn btn-primary org-bg-btn">Sign Up</button>
          </form>
        </div>
        <div className="col-md-6 d-flex align-items-center justify-content-center right-signup-bg">
          <div className="welcome-message">
            <h1>Welcome Back!</h1>
            <p>To keep connected with us please login with your personal info</p>
            <button 
            class="white-bg-btn"
              
              onClick={() => setShowSignup(false)}
            >
              Login
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SignupForm;
