import * as React from 'react';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import StepContent from '@mui/material/StepContent';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';

const steps = [
  {
    label: 'Step 1: Yes/No Questions',
    fields: [
      { name: 'selfClosingDoors', label: 'Are the doors self-closing?' },
    ],
  },
  {
    label: 'Step 2: Description Questions',
    fields: [
      { name: 'adjoiningBuildings', label: 'Do you have adjoining buildings?' },
      { name: 'numberOfFloors', label: 'How many floors do you have?' },
      { name: 'numberOfWindows', label: 'How many windows do you have?' },
      { name: 'numberOfRooms', label: 'How many rooms do you have?' },
      { name: 'numberOfBathrooms', label: 'How many bathrooms do you have?' },
      { name: 'numberOfExternalDoors', label: 'How many external doors do you have?' },
      { name: 'numberOfKitchens', label: 'How many kitchens do you have?' },
      { name: 'spaceUsageOfficeRegular', label: 'Office space used regularly' },
      { name: 'spaceUsageOfficeSometimes', label: 'Office space used sometimes' },
      { name: 'spaceUsageKitchenBathroom', label: 'Kitchen and bathroom space' },
      { name: 'spaceUsageRedundant', label: 'Redundant space not used' },
    ],
  },
];

export default function VerticalLinearStepper() {
  const [activeStep, setActiveStep] = React.useState(0);
  const [formData, setFormData] = React.useState({
    selfClosingDoors: '',
    adjoiningBuildings: '',
    numberOfFloors: '',
    numberOfWindows: '',
    numberOfRooms: '',
    numberOfBathrooms: '',
    numberOfExternalDoors: '',
    numberOfKitchens: '',
    spaceUsageOfficeRegular: '',
    spaceUsageOfficeSometimes: '',
    spaceUsageKitchenBathroom: '',
    spaceUsageRedundant: '',
  });

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
    setFormData({
      selfClosingDoors: '',
      adjoiningBuildings: '',
      numberOfFloors: '',
      numberOfWindows: '',
      numberOfRooms: '',
      numberOfBathrooms: '',
      numberOfExternalDoors: '',
      numberOfKitchens: '',
      spaceUsageOfficeRegular: '',
      spaceUsageOfficeSometimes: '',
      spaceUsageKitchenBathroom: '',
      spaceUsageRedundant: '',
    });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = () => {
    console.log('Form Data Submitted:', formData);
  };

  return (
    <Box sx={{ maxWidth: 800 }}>
      <Stepper activeStep={activeStep} orientation="vertical">
        {steps.map((step, index) => (
          <Step key={step.label}>
            <StepLabel>{step.label}</StepLabel>
            <StepContent>
              {step.fields.map((field) => (
                step.label === 'Step 1: Yes/No Questions' ? (
                  <FormControl fullWidth key={field.name} sx={{ mt: 2 }}>
                    <InputLabel 
                      sx={{ background: '#fff7e1', px: 1 }}
                    >
                      {field.label}
                    </InputLabel>
                    <Select
                      name={field.name}
                      value={formData[field.name]}
                      onChange={handleChange}
                      label={field.label}
                    >
                      <MenuItem value="yes">Yes</MenuItem>
                      <MenuItem value="no">No</MenuItem>
                    </Select>
                  </FormControl>
                ) : (
                  <TextField
                    key={field.name}
                    name={field.name}
                    label={field.label}
                    value={formData[field.name]}
                    onChange={handleChange}
                    fullWidth
                    sx={{ mt: 2 }}
                  />
                )
              ))}
              <Box sx={{ mb: 2 }}>
                <div>
                  <Button
                    variant="contained"
                    onClick={handleNext}
                    sx={{ mt: 1, mr: 1 }}
                  >
                    {index === steps.length - 1 ? 'Finish' : 'Continue'}
                  </Button>
                  <Button
                    disabled={index === 0}
                    onClick={handleBack}
                    sx={{ mt: 1, mr: 1 }}
                  >
                    Back
                  </Button>
                </div>
              </Box>
            </StepContent>
          </Step>
        ))}
      </Stepper>
      {activeStep === steps.length && (
        <Paper square elevation={0} sx={{ p: 3 }}>
          <Typography>All steps completed - you're finished</Typography>
          <Button
            variant="contained"
            onClick={handleSubmit}
            sx={{ mt: 1, mr: 1 }}
          >
            Submit
          </Button>
          <Button onClick={handleReset} sx={{ mt: 1, mr: 1 }}>
            Reset
          </Button>
        </Paper>
      )}
    </Box>
  );
}
