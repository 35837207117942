import './App.css';
import React, { useState } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import LoginForm from './components/LoginForm'; // Ensure this path is correct
import SignupForm from './components/SignupForm'; // Ensure this path is correct
import Home from './pages/Home'; // Ensure this path is correct
import Baseline from './pages/Baseline'; // Import Baseline page
import Stepper from './pages/Stepper'; 
import Bill from './pages/Bill'; // Import Baseline page
import CarbonEmission from './pages/CarbonEmissions'; // Import Baseline page
import Timeline from './pages/Timeline'; // Import Baseline page
import Beeboard from './pages/Beeboard'; // Import Baseline page
import Actionplan from './pages/Actionplan';
import Myprogress from './pages/Myprogress';
import Reducingemission from './pages/Reducingemission';
import 'bootstrap/dist/css/bootstrap.min.css';

import Header from './components/Header';


function App() {
  return (
    <Router>
      <Header/>
      <div className="App container tabs-bg">
        <Routes>
          <Route path="/" element={<Home />} /> {/* Home route */}
          <Route path="/login" element={<LoginForm />} />
          <Route path="/signup" element={<SignupForm />} />
          <Route path="/baseline" element={<Baseline />} /> {/* Baseline route */}
          <Route path="/Stepper" element={<Stepper />} /> {/* Baseline route */}
          <Route path="/Bill" element={<Bill />} /> {/* Baseline route */}
          <Route path="/CarbonEmission" element={<CarbonEmission />} /> {/* Baseline route */}
          <Route path="/Timeline" element={<Timeline />} /> {/* Baseline route */}
          <Route path="/Beeboard" element={<Beeboard />} /> {/* Baseline route */}
          <Route path="/Actionplan" element={<Actionplan />} /> {/* Baseline route */}
          <Route path="/Myprogress" element={<Myprogress />} /> {/* Baseline route */}
          <Route path="/Reducingemission" element={<Reducingemission />} /> {/* Baseline route */}
        </Routes>
      </div>
    </Router>
  );
}

export default App;
