import React from "react";
import Col from "react-bootstrap/Col";
import Nav from "react-bootstrap/Nav";
import Row from "react-bootstrap/Row";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import './Reducingemission.css';

export default function Reducingemission() {
  return (
    <div className="beadboardmain">
      <h3 className="allh3">REDUCING EMISSIONS</h3>

      <Tab.Container id="left-tabs-example" defaultActiveKey="light">
        <Row>
          <Col sm={3}>
            <Nav variant="pills" className="flex-column">
              <Nav.Item>
                <Nav.Link eventKey="light">Light</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="hvac">HVAC</Nav.Link>
              </Nav.Item>
          
            <Nav.Item>
                <Nav.Link eventKey="building">Building Fabric</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="hotwater">Hotwater</Nav.Link>
              </Nav.Item>
       
            <Nav.Item>
                <Nav.Link eventKey="awarness">Awareness</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="equipment">Equipment</Nav.Link>
              </Nav.Item>
          
            <Nav.Item>
                <Nav.Link eventKey="work">Work Environment
              </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="property">  Property</Nav.Link>
              </Nav.Item>
              </Nav>
          </Col>
          <Col sm={9}>
            <Tab.Content>
              <Tab.Pane eventKey="light">
                {" "}
                <Tabs
                  defaultActiveKey="profile"
                  id="fill-tab-example"
                  className="mb-3"
                  fill
                >
                  <Tab eventKey="home" title="Product changes">
                    Tab content for Home
                  </Tab>
                  <Tab eventKey="profile" title="Behavioral changes">
                    Tab content for Profile
                  </Tab>
                  <Tab eventKey="longer-tab" title="Cost Saving Analysis">
                    Tab content for Loooonger Tab
                  </Tab>
                  <Tab eventKey="contact" title="Green Suppliers" >
                    Tab content for Contact
                  </Tab>
                  <Tab eventKey="funds" title="Funds" >
                    Tab content for Contact
                  </Tab>
                </Tabs>
              </Tab.Pane>
              <Tab.Pane eventKey="hvac">Second tab content</Tab.Pane>
              <Tab.Pane eventKey="building">Second tab content</Tab.Pane>
            
              <Tab.Pane eventKey="hotwater">
                {" "}
                <Tabs
                  defaultActiveKey="profile"
                  id="fill-tab-example"
                  className="mb-3"
                  fill
                >
                  <Tab eventKey="home" title="Product changes">
                    Tab content for Home
                  </Tab>
                  <Tab eventKey="profile" title="Behavioral changes">
                    Tab content for Profile
                  </Tab>
                  <Tab eventKey="longer-tab" title="Cost Saving Analysis">
                    Tab content for Loooonger Tab
                  </Tab>
                  <Tab eventKey="contact" title="Green Suppliers" >
                    Tab content for Contact
                  </Tab>
                  <Tab eventKey="funds" title="Funds" >
                    Tab content for Contact
                  </Tab>
                </Tabs>
              </Tab.Pane>
              <Tab.Pane eventKey="awarness">Second tab content</Tab.Pane>
              <Tab.Pane eventKey="equipment">Second tab content</Tab.Pane>
              <Tab.Pane eventKey="work">Second tab content</Tab.Pane>
              <Tab.Pane eventKey="property">Second tab content</Tab.Pane>
            </Tab.Content>
          </Col>
        </Row>
      </Tab.Container>
    </div>
  );
}
