import * as React from 'react';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import StepContent from '@mui/material/StepContent';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';

const steps = [
  {
    label: 'Step 1: Yes/No Questions',
    fields: [
      { name: 'roofInsulated', label: 'Is the roof insulated, and does it meet modern heating standards? e.g. Trombe Wall' },
      { name: 'buildingDefects', label: 'Is the building free from defects and cracks?' },
      { name: 'doorsCondition', label: 'Are the doors in good condition?' },
      { name: 'windowsDoubleGlazed', label: 'Are the windows double-glazed for improved insulation?' },
      { name: 'windowsCrackedDamaged', label: 'Are any windows cracked or damaged?' },
      { name: 'doorsLeftOpen', label: 'How often are doors left open, especially in loading areas accessible to vehicles?' },
    ],
  },
  {
    label: 'Step 2: Description Questions',
    fields: [
      { name: 'dampnessDescription', label: 'Describe any dampness in the property.' },
      { name: 'windowDamageDescription', label: 'Describe the damage to cracked or damaged windows e.g. moisture between the panes.' },
      { name: 'spaceUtilization', label: 'Is the available space utilized efficiently, or is there any unused space?' },
    ],
  },
];

export default function VerticalLinearStepper() {
  const [activeStep, setActiveStep] = React.useState(0);
  const [formData, setFormData] = React.useState({
    roofInsulated: '',
    buildingDefects: '',
    doorsCondition: '',
    windowsDoubleGlazed: '',
    windowsCrackedDamaged: '',
    doorsLeftOpen: '',
    dampnessDescription: '',
    windowDamageDescription: '',
    spaceUtilization: '',
  });

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
    setFormData({
      roofInsulated: '',
      buildingDefects: '',
      doorsCondition: '',
      windowsDoubleGlazed: '',
      windowsCrackedDamaged: '',
      doorsLeftOpen: '',
      dampnessDescription: '',
      windowDamageDescription: '',
      spaceUtilization: '',
    });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = () => {
    console.log('Form Data Submitted:', formData);
  };

  return (
    <Box sx={{ maxWidth: 600 }}>
      <Stepper activeStep={activeStep} orientation="vertical">
        {steps.map((step, index) => (
          <Step key={step.label}>
            <StepLabel>{step.label}</StepLabel>
            <StepContent>
              {step.fields.map((field) => (
                step.label === 'Step 1: Yes/No Questions' ? (
                  <FormControl fullWidth key={field.name} sx={{ mt: 2 }}>
                    <InputLabel 
                      sx={{ background: '#fff7e1', px: 1 }}
                    >
                      {field.label}
                    </InputLabel>
                    <Select
                      name={field.name}
                      value={formData[field.name]}
                      onChange={handleChange}
                      label={field.label}
                    >
                      <MenuItem value="yes">Yes</MenuItem>
                      <MenuItem value="no">No</MenuItem>
                    </Select>
                  </FormControl>
                ) : (
                  <TextField
                    key={field.name}
                    name={field.name}
                    label={field.label}
                    value={formData[field.name]}
                    onChange={handleChange}
                    fullWidth
                    sx={{ mt: 2 }}
                  />
                )
              ))}
              <Box sx={{ mb: 2 }}>
                <div>
                  <Button
                    variant="contained"
                    onClick={handleNext}
                    sx={{ mt: 1, mr: 1 }}
                  >
                    {index === steps.length - 1 ? 'Finish' : 'Continue'}
                  </Button>
                  <Button
                    disabled={index === 0}
                    onClick={handleBack}
                    sx={{ mt: 1, mr: 1 }}
                  >
                    Back
                  </Button>
                </div>
              </Box>
            </StepContent>
          </Step>
        ))}
      </Stepper>
      {activeStep === steps.length && (
        <Paper square elevation={0} sx={{ p: 3 }}>
          <Typography>All steps completed - you're finished</Typography>
          <Button
            variant="contained"
            onClick={handleSubmit}
            sx={{ mt: 1, mr: 1 }}
          >
            Submit
          </Button>
          <Button onClick={handleReset} sx={{ mt: 1, mr: 1 }}>
            Reset
          </Button>
        </Paper>
      )}
    </Box>
  );
}
