import React from 'react';
import './Baseline.css'; // Import CSS file for styling
import { Link } from 'react-router-dom';
import { ReactComponent as BeeIcon } from "../assets/bee.svg"; // Adjust path if necessary

function Baseline() {
  return (
    <div className="baseline-container">
      <div className="top-column" >
        <h1>Let’s measure your business’s <br></br>Scope 1 and 2 carbon emissions.</h1>
        <h3>It’ll hardly take 1-2 working days.</h3>
        <h3>Baseline year: 2023</h3>
        <Link to="/Stepper">
            <button className="button">Let’s be Sustainable!</button>
          </Link>
      </div>
      <div className='bottom-main-col'>
      <div className='bottom-left-col'>
      <h3> Answer all applicable questions.</h3>
      <p>If you have questions, please reach out. </p>
      <div className='button-main'>
      <Link to="/Stepper">
            <button className="button">Send a message</button>
          </Link>
          <Link to="/Stepper">
            <button className="button">Book a call</button>
          </Link>
          </div>
      </div>
      <div className="bottom-column">
        <div className="bottom-content">
         
        
          <h2>Instructions</h2>
          <p>
            The more we know, the better we can help 
          <br></br> Prioritising accuracy ensures we achieve our goals more effectively.</p>
        
         
        </div>
      </div>
      </div>
      <div className="icon-container">
        <span className="icon">  {" "}
        <BeeIcon width="50" height="50" />{" "}</span> {/* Replace with your icon */}
      </div>
    </div>
  );
}

export default Baseline;
