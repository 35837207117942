import React from "react";
import "./CarbonEmissions.css";
import HiveIcon from "@mui/icons-material/Hive";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import DeleteSweepIcon from "@mui/icons-material/DeleteSweep";
import Timeline from './Timeline';
import { Link } from 'react-router-dom';

export default function CarbonEmissions() {
  return (
    <main className="main-section">
      <section className="section-1">
        <div className="left">
          <div className="title-paragraph">
            <h3 className="allh3">Your carbon emissions</h3>
            <h2>Scope 2 carbon emissions: 2900 kg CO2</h2>
            <h5>See how we measured it.</h5>
            <p>Initiis’ highest carbon emissions are Scope 2 emissions</p>
          </div>
        </div>
        <div className="right">
          <div className="image-grid">
            <img src="image1.jpg" alt="Image 1" />
            <img src="image2.jpg" alt="Image 2" />
            <img src="image3.jpg" alt="Image 3" />
            <img src="image4.jpg" alt="Image 4" />
          </div>
        </div>
      </section>

      <section className="section-2">
        <h3 className="allh3">Setting Emission Targets</h3>
        <div className="timeline">
        <Timeline />
        </div>
      </section>

      <section className="section-3">
        <h3 className="allh3">
          Cost effective areas for <br></br>Initiis to reduce emissions
        </h3>
      </section>

      <section className="section-4">
        <div className="grid">
          <div className="grid-item-internal">
            <div className="hexagon-internal">
              <h4>Beehavioural changes</h4>
              <div>
                <HiveIcon />
                <HiveIcon />
              </div>
              <div>
                <div>
                  <DeleteOutlineIcon />
                </div>
                <div>
                  <DeleteSweepIcon /> <DeleteSweepIcon /> <DeleteSweepIcon />
                  <DeleteSweepIcon />
                </div>
              </div>
            </div>
          </div>
          <div className="grid-item-internal">
            <div className="hexagon-internal">
            <h4>Beehavioural changes</h4>
              <div>
                <HiveIcon />
                <HiveIcon />
              </div>
              <div>
                <div>
                  <DeleteOutlineIcon />
                </div>
                <div>
                  <DeleteSweepIcon /> <DeleteSweepIcon /> <DeleteSweepIcon />
                  <DeleteSweepIcon />
                </div>
              </div>
              
            </div>
          </div>
          <div className="grid-item-internal">
            <div className="hexagon-internal">
            <h4>Beehavioural changes</h4>
              <div>
                <HiveIcon />
                <HiveIcon />
              </div>
              <div>
                <div>
                  <DeleteOutlineIcon />
                </div>
                <div>
                  <DeleteSweepIcon /> <DeleteSweepIcon /> <DeleteSweepIcon />
                  <DeleteSweepIcon />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section>
      <Link to="/Beeboard">
            <button className="button">Bee Board</button>
          </Link>
      </section>
    </main>
  );
}
