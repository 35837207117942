import React from "react";
import "./Home.css"; // Import CSS file
import { Link } from "react-router-dom";
import EastIcon from '@mui/icons-material/East';

function Home() {
  return (
    <div className="section">
      <div className="heading-container">
        <h1>Welcome to your sustainability journey</h1>
        <h3></h3>
      </div>
      <div className="grid-container">
        <div className="hexagon">Calculate Carbon <br></br> Emissions</div>
        <div className="hexagon">Set Reduction <br></br> Goals </div>
        <div className="hexagon">Reduce <br></br> Emissions</div>
        <div className="hexagon">Staff <br></br> Training </div>
        <div className="hexagon">Adopt Circular <br></br> Economy</div>
        <div className="hexagon">Certifications</div>
      </div>
      <div className="grid-container-4">
        <div className="box">Summary</div>
        <div className="box">See My Actions</div>
        <div className="box">Download Full Report</div>
        <div className="box">See My Progress</div>
      </div>
      <div className="arrow-container">
        <Link to="/Baseline" className="arrow">
        <EastIcon/></Link>
      </div>
    </div>
  );
}

export default Home;
